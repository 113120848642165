import {
  AlertModal,
  Button,
  Heading,
  Pill,
  Stack,
  Table,
  TableColumn,
  TableProvider,
  Text,
  useConfirmation,
} from '@sixfold/common-ui';
import { BackofficeScope } from '@sixfold/session-interface';
import React from 'react';

import { FeatureFlagAuditLogDrawer, FeatureFlagAuditLogEntry } from './feature_flag_audit_log_drawer';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { FeatureFlagMetadata, notEmpty } from '../../lib/graphql';

export interface FeatureFlagsViewProps {
  allFlags: (FeatureFlagMetadata | null)[];

  onFlagsChange(newFlags: Record<string, boolean>): Promise<void>;

  currentSetFlags: Record<string, boolean>;
  auditLog: FeatureFlagAuditLogEntry[];
  loadMoreAuditLogs: () => Promise<unknown> | undefined;
}

export const FeatureFlagsView: React.FC<FeatureFlagsViewProps> = ({
  allFlags,
  onFlagsChange,
  currentSetFlags,
  auditLog,
  loadMoreAuditLogs,
}) => {
  const isAdmin = useIsAdmin();
  const hasCompanyEditPermission = useHasScopes([BackofficeScope.companyWriteCompany]);
  const isPrivilegedUser = isAdmin || hasCompanyEditPermission;

  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const { pending, confirm, cancel, proceed, context } = useConfirmation<{
    featureId: string;
    featureDisplayName: string;
  }>();

  const handleFeatureToggle = React.useCallback(
    async (featureId: string, featureDisplayName: string) => {
      const confirmed = await confirm({ featureId, featureDisplayName });

      if (confirmed) {
        const newFlags = { ...currentSetFlags };
        const previousValue = currentSetFlags[featureId];
        newFlags[featureId] = !previousValue;
        return await onFlagsChange(newFlags);
      }
    },
    [confirm, currentSetFlags, onFlagsChange],
  );

  const data = React.useMemo(
    () => allFlags.filter(notEmpty).map((flag) => ({ id: flag.feature_flag_id, action: undefined, ...flag })),
    [allFlags],
  );

  const columns: TableColumn<
    FeatureFlagMetadata & {
      id: string;
      action?: () => void;
    }
  >[] = [
    {
      id: 'display_name',
      minWidth: '200px',
      title: 'Name',
      cellContent: ({ value }) => <Text weight="bold">{value}</Text>,
    },
    {
      id: 'action',
      width: '175px',
      title: 'Status',
      cellContent: ({ row }) => (
        <>
          <Stack justifyContent="space-between">
            <Pill kind={currentSetFlags[row.id] ? 'success' : 'neutral'}>
              {currentSetFlags[row.id] ? 'Enabled' : 'Disabled'}
            </Pill>
            <Button
              kind="subtle"
              disabled={!isPrivilegedUser}
              ref={buttonRef}
              onClick={() => handleFeatureToggle(row.id, row.display_name)}>
              {currentSetFlags[row.id] ? 'Disable' : 'Enable'}
            </Button>
          </Stack>
        </>
      ),
    },
    {
      id: 'domains',
      width: '150px',
      title: 'Domain(s)',
      cellContent: ({ value, row }) => {
        if (!Array.isArray(value)) {
          return null;
        }
        return (
          <Stack>
            {value.map((tag) => (
              <Pill key={row.id + tag}>{tag}</Pill>
            ))}
          </Stack>
        );
      },
    },
    {
      id: 'description',
      minWidth: '250px',
      title: 'Description',
    },
    {
      id: 'usage',
      minWidth: '250px',
      title: 'Usage',
    },
  ];

  const actionName = currentSetFlags[context?.featureId ?? ''] ? 'disable' : 'enable';

  return (
    <div className="company__featureFlags-container">
      <Stack justifyContent="space-between">
        <Heading>Feature Flags</Heading>
        {auditLog !== null && (
          <FeatureFlagAuditLogDrawer allFlags={allFlags} auditLog={auditLog} loadMoreAuditLogs={loadMoreAuditLogs} />
        )}
      </Stack>
      {/* Table provider with initial item count is used so that users could Ctrl+F on rows that are not yet visible */}
      <TableProvider value={{ initialItemCount: data.length }}>
        <Table className="company__featureFlags-list" data={data} columns={columns} />
      </TableProvider>
      <AlertModal
        open={pending}
        onClose={cancel}
        description={
          <Text>
            <Text>Are you sure you want to </Text>
            <Text weight="bold">{actionName}</Text>
            <Text> the following feature flag: </Text>
            <Text weight="bold">{context?.featureDisplayName ?? ''}</Text>
            <Text>?</Text>
          </Text>
        }
        title="Confirm feature flag value change"
        trigger={buttonRef.current}>
        <Button kind="subtle" onClick={cancel}>
          Cancel
        </Button>
        <Button kind="primary" onClick={proceed}>
          Confirm
        </Button>
      </AlertModal>
    </div>
  );
};
